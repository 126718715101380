import store from '@/store'
import axios from 'axios'
store.subscribe((mutation) => {

    switch (mutation.type) {
        case "authentication/SET_TOKEN":

            if (mutation.payload) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
                localStorage.setItem('token', mutation.payload)
            } else {
                axios.defaults.headers.common['Authorization'] = null
                localStorage.removeItem('token')
            }
            break;
        case "authentication/SET_USER":
            localStorage.setItem('user', JSON.stringify(mutation.payload));
            break;
        case 'episodes/setEpisodes':
            localStorage.setItem('episodes', JSON.stringify(mutation.payload));
            break;
        default:
            //  console.log(`Unexpected Subscriber Mutation: ${mutation.type}`);
    }
})