export default {

  addImmunization({ commit }, data) {
    
    commit('addImmunization', data)
  },

  updateImmunization({ state, commit }, data) {
    
    const index = state.episode.immunizations.findIndex(item => item.id === data.id)

    if (index >= 0) {

      data = {
        value: data,
        index: index,
      }
      
      commit('updateImmunization', data)
    }
  },

  deleteImmunization({ state, commit }, data) {

    if (data.action === 'C') {
      
      const index = state.episode.immunizations.findIndex((immunization) => {
      
        return data.id === immunization.id
      })

      if (index >= 0) {
      
        commit('removeImmunizationFromArray', index)
        return
      }

      console.log('Immunization Not Found: ', data)
      return
    }

    const index = state.episode.immunizations.findIndex((immunization) => {
    
      return data.id === immunization.id
    })

    if (index >= 0) {
   
      commit('updateImmunizationToDeleted', index)
    } else {

      console.error("Immunization Not Found: ", data, index)
      console.table(state.episode.immunizations)
    }
  }
}