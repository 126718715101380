export default {
    medicationsBeforeHospitalization(state) {
        
        return state.episode.episode_medications.filter(medication => {
            
            return medication.status === 'before' && medication.is_disabled === 0 && medication.action !== 'D'
        })
    },
    medicationsAfterHospitalization(state) {
        return state.episode.episode_medications.filter(medication => {
            
            return medication.status === 'after' && medication.is_disabled === 0 && medication.action != 'D'
        })
    },
    medicationsCurrent(state) {
        
        return state.episode.episode_medications.filter(medication => {
            
            return (medication.is_currently_taking === 1 || medication.is_currently_taking === true)
                && medication.is_disabled === 0 && medication.action !== 'D'
        })
    }
}