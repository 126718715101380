export default {

    getSignsAndSymptoms(state) {
        return state.episode.signsAndSymptoms.filter(
            (signsAndSymptom) => signsAndSymptom.action !== 'D'
        );
    },

    getCurrentSignsAndSymptom(state) {

        return state.signsAndSymptom;
    },

    getSignsAndSymptom(state) {
        return state.episode?.signsAndSymptoms?.find(
            (signsAndSymptom) =>
                signsAndSymptom.signs_and_symptoms_id === state.signsAndSymptom.signs_and_symptoms_id && signsAndSymptom.action !== 'D'
        );
    },

    hasRequiredNotifications(state) {

        return state.episode?.signsAndSymptoms?.some(signsAndSymptom => signsAndSymptom.requires_notification === 1 && signsAndSymptom.status === 1) ?? false

    },

    requiredNotificationSignsAndSymptoms(state) {
        return state.episode?.signsAndSymptoms.filter(signsAndSymptom => signsAndSymptom.status === 1 && signsAndSymptom.requires_notification === 1 && signsAndSymptom.has_notified === 0).map(signsAndSymptom => signsAndSymptom.id);
    },

    getAssessment(state) {

        return state.assessment
    },
};
