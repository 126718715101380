import Vue from 'vue'
import Vuex from 'vuex'
import { createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import authentication from './authentication'
import configuration from './application/configuration'
import immunization from './immunizations'
import episodes from './modules/episodes'
import episode from './modules/episode/episode'
import user from './modules/user'
import messages from './modules/messages'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const dataState = createPersistedState({
    key: 'aptus',
    storage: {
        getItem: (key) => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value),
        removeItem: (key) => localStorage.removeItem(key),
    }
});

export default new Vuex.Store({
    strict: debug,
    plugins: debug ? [createLogger(), dataState] : [dataState],
    state: {
        isChatWindowOpen: false,
        interventionId: 0,
    },
    getters: {
        isChatWindowOpen: state => state.isChatWindowOpen,
        getInterventionId: state => state.interventionId,
    },
    mutations: {
        setIsChatWindowOpen(state, openValue){
            state.isChatWindowOpen = openValue;
        },
        setSymptomForMessage(state, symptom){
            state.symptomForMessage = symptom;
        },
        setDoSignsAndSymptomsSave(state, doValue) {
            state.doSignsAndSymptomsSave = doValue;
        },
        setSignSymptomStep(state, step) {
            state.signSymptomStep = step;
        },
        setSymptomHasSymptom(state, hasSymptom) { 
            state.symptomHasSymptom = hasSymptom;
        },
        setSignAndSymptomsRequiresAlert(state, mustAlert) {

            state.signAndSymptomsRequiresAlert = mustAlert;
        },
        setInterventionId(state, interventionId) {

            state.interventionId = interventionId;
        }
    },
    actions: {},
    modules: {
        authentication,
        user,
        configuration,
        immunization,
        episode,
        episodes,
        messages,
    }
})