export default {

  addOrUpdateCareTeamMember({state, commit}, data) {

    //  does this care team member already exist?
    const index = state.episode.care_teams.findIndex((provider) => {

      return data.name === provider.name
    })

    if(index >= 0) {

      data.action = state.episode.care_teams[index].action !== 'C' ? 'U' : 'C'

      commit('updateCareTeamMember', {index: index, provider: data })
      return
    }

    commit('addCareTeamMember', data)

  },
  addCareTeamMember({ dispatch }, data) {

    // commit('addCareTeamMember', data)
    dispatch('addOrUpdateCareTeamMember', data)
  },

  removeCareTeamMember({ commit, state }, data) {

    const index = state.episode.care_teams.findIndex((item) => {

      return data.name === item.name
    })

    if (index >= 0) {

      if(state.episode.care_teams[index].action === 'C') {

        commit('scrubCareTeamMember', index)
        return
      }

      commit('removeCareTeamMember', index)
      return
    }

    console.error("Tried to delete care team member that did not exist with ID: ", data.name)
  },

  updateCareTeamMember({ state, commit }, item) {
    const index = state.episode.care_teams.findIndex((careTeam) => item.id === careTeam.id);

    if (index >= 0) {
      let data = {
        index: index,
        data: item
      }

      commit('updateCareTeamMember', data)
      return
    }

    console.log("Could not update Care Team Member: ", item)
  },
}