export default {

  updateMedications(state, data) {
    
    state.episode.episode_medications.push(data)
  },

  removeMedicationFromArray(state, index) {

    state.episode.episode_medications.splice(index, 1)
    
  },
  updateMedicationToDeleted(state, index) {
    
    state.episode.episode_medications[index].action = 'D'
  },

  updateMedication(state, data) {

    state.episode.episode_medications[data.index] = data.medication
    
    if (state.episode.episode_medications[data.index].action !== 'C') {
     
      state.episode.episode_medications[data.index].action = 'U'
    }

    state.episode.episode_medications = [... state.episode.episode_medications]
  }

}