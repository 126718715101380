export default {
    isValid(state) {

        return state.episode.isValid
    },

    validationErrors(state) {

        return state.episode.validationErrors
    },
}