import axios from 'axios';

const state = {
    immunizations: [],
    patientImmunizations: [],
    
};

const getters = {
    allImmunizations: (state) => state.immunizations,
    patientImmunizations: (state) => state.patientImmunizations,
};

const actions = {

    async unload({commit}) {

        commit('setImmunizations', [])
        commit('setPatientImmunizations', [])
    },

    async fetchImmunizations({ commit }) {

        let response = null;

        try {

            response = await axios.get(`immunizations`)
        } catch (error) {

            if (error.status === 404) {
                
                console.warn("There aren't any immunizations loaded in the system.")
            }

            if (error.status != 404) {
                
                console.error("An error occured communicating with the server: ", error)
            }

            response = {
                data: []
            }
        }
        
        //  const response = await axios.get('immunizations');

        //  console.info("Immunizations: ", response.data)
        commit('setImmunizations', response.data);
    },

    async deleteImmunization({ commit }, id) {
        
        await axios.delete('/delete-patient-immunization/'+id);

         commit('removeImmunization', id);
    },

    async fetchPatientImmunizations({ commit }, patient_id) {
        
        const immunizations = await axios.get(`patient-immunizations/user/${patient_id}`)
            .then((response) => {
                
                return response.data.immunizations
            })
            .catch(() => {
                
                return []
            })
        
        commit('setPatientImmunizations', immunizations);
    },
};

const mutations = {
    setImmunizations: (state, immunizations) => (state.immunizations = immunizations),
    removeImmunization: (state, id) => state.immunizations = state.immunizations.filter(immunization => immunization.id !== id),
    setPatientImmunizations: (state, patientImmunizations) => (state.patientImmunizations = patientImmunizations),

};

export default {
    state,
    getters,
    actions,
    mutations
}