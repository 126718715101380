import Vue from "vue";
import App from "./App.vue";
import store from "./store"
import router from "./router";
import axios from "axios";
import popover from "vue-js-popover";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret, faTimes, faPlus, faSearch, faChevronDown, faAddressCard, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@/assets/styles.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";
import './filters/formatTimeStamp'
require('@/store/subscriber')

library.add(faUserSecret, faTimes, faSearch, faChevronDown, faPlus, faAddressCard, faCheck);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(popover);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(function (config) {
  config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  console.warn(`${config.method.toUpperCase()} sent to ${axios.defaults.baseURL}${config.url}`)
  return config
}, function (error) {
  console.error("An error occurred in AXIOS request")
  return Promise.reject(error)
});

Vue.prototype.$defaultTimeOut = process.env.VUE_APP_TIMEOUT ?? 10 * 60 * 1000 // 10 minutes.
Vue.prototype.$defaultTWarningTimeOut = process.env.VUE_APP_WARNING ?? 8 * 60 * 1000 // 8 minutes.

new Vue({
  store,
  router,
  render: (h) => h(App),
  data: function () {
    return {
      logged_user: {
        auth_to_add: true,
      },
    };
  },
}).$mount("#app");
