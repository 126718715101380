export default {
    setSignAndSymptom({commit, state}, data) {

        const index = state.episode.signsAndSymptoms.findIndex((signsAndSymptom) => signsAndSymptom.sign_and_symptom_id === data.sign_and_symptom_id)

        if (index >= 0) {

            const update = {
                index: index,
                value: data,
            }

            commit("setSignsAndSymptom", update)
        }
    },

    setNewSignAndSymptom({commit}, data) {

        commit("setSignsAndSymptom", data)
    },

    addOrUpdateSignsAndSymptom({commit, state}) {

        const assessment = state.assessment

        const index = state.episode.signsAndSymptoms.findIndex(item => item.signs_and_symptom_id === assessment.signs_and_symptom_id && item.action !== 'D')

        if (index >= 0) {

            if(assessment.action && assessment.action === 'C') {
                assessment.action = 'C'
            } else {

                assessment.action = 'U'
            }

            commit("updateSignsAndSymptom", {index: index, value: assessment})
            return
        }

        assessment.action = 'C'
        commit("addSignsAndSymptom", assessment)
    },

    clearChangeInConditionByEscalationId({commit, state}, escalationId) {

        const itemsToSetToComplete = state.episode.signsAndSymptoms.filter(sign => sign.episode_intervention_id === escalationId).map(changeInCondition => {
            if(changeInCondition.action === 'C') {

                return
            }

            return {
                action: 'U',
                episode_id: changeInCondition.episode_id,
                has_notified: changeInCondition.has_notified,
                id: changeInCondition.id,
                immediacy: changeInCondition.immediacy,
                is_immediate: changeInCondition.immediacy === 'true',
                is_reoccurrence: changeInCondition.is_reoccurrence,
                last_treatment: changeInCondition.last_treatment,
                negative_factors: changeInCondition.negative_factors,
                other_factors: changeInCondition.other_factors,
                positive_factors: changeInCondition.positive_factors,
                requires_notification: changeInCondition.requires_notification,
                severity_direction: changeInCondition.severity_direction,
                signs_and_symptom_id: changeInCondition.signs_and_symptom_id,
                started_at: changeInCondition.started_at,
                status: 0
            }
        })

        const cleanedItemsToComplete = itemsToSetToComplete.filter(item => item !== undefined)


        const itemsToRetain = state.episode.signsAndSymptoms.filter(sign => sign.episode_intervention_id !== escalationId)

        const newSignsAndSymptoms = [...new Set([...itemsToRetain, ...cleanedItemsToComplete])]

        console.log(newSignsAndSymptoms)

        if(newSignsAndSymptoms.length > 0) {

            commit("setSignsAndSymptoms", newSignsAndSymptoms)
        }else {
            commit("setSignsAndSymptoms", [])
        }
    },

    deleteSignsAndSymptom({commit, state}, data) {

        const itemsToDelete = state.episode.signsAndSymptoms.filter(sign => sign.signs_and_symptom_id === data).map((symptom) => {
                if(symptom.action === 'C') {

                    return
                }

                return {
                    action: 'D',
                    episode_id: symptom.episode_id,
                    has_notified: symptom.has_notified,
                    id: symptom.id,
                    immediacy: symptom.immediacy,
                    is_immediate: symptom.immediacy === 'true',
                    is_reoccurrence: symptom.is_reoccurrence,
                    last_treatment: symptom.last_treatment,
                    negative_factors: symptom.negative_factors,
                    other_factors: symptom.other_factors,
                    positive_factors: symptom.positive_factors,
                    requires_notification: symptom.requires_notification,
                    severity_direction: symptom.severity_direction,
                    signs_and_symptom_id: symptom.signs_and_symptom_id,
                    started_at: symptom.started_at,
                    status: symptom.status
                }
        })

        const cleanedItemsToDelete = itemsToDelete.filter(item => item !== undefined)


        const itemsToRetain = state.episode.signsAndSymptoms.filter(sign => sign.signs_and_symptom_id !== data)

        console.log(itemsToDelete)
        console.log(itemsToRetain)

        const newSignsAndSymptoms = [...new Set([...itemsToRetain, ...cleanedItemsToDelete])]

        console.log(newSignsAndSymptoms)

        if(newSignsAndSymptoms.length > 0) {

            commit("setSignsAndSymptoms", newSignsAndSymptoms)
        }else {
            commit("setSignsAndSymptoms", [])
        }
    },

    setAssessmentImmediacy({commit}, data) {

        commit("assessmentImmediacy", data)
    },
    setAssessmentStartedAt({commit}, data) {

        commit("assessmentStartedAt", data)
    },
    setAssessmentSeverityDirection({commit}, data) {

        commit("assessmentSeverityDirection", data)
    },
    setAssessmentNegativeFactors({commit}, data) {

        commit("assessmentNegativeFactors", data)
    },
    setAssessmentPositiveFactors({commit}, data) {

        commit("assessmentPositiveFactors", data)
    },
    setAssessmentLastTreatment({commit}, data) {

        commit("assessmentLastTreatment", data)
    },
    setAssessmentOtherFactors({commit}, data) {

        commit("assessmentOtherFactors", data)
    },
    setAssessmentIsReoccurrence({commit}, data) {

        commit("assessmentIsReoccurrence", data)
    },

    clearAssessment({commit}) {

        commit("resetAssessment")
    },

    setAssessment({commit}, value) {

        commit("setAssessment", value)
    },
}