export default {

  getInterventionByName(state, value) {

    return state.episode.interventions.filter(intervention => intervention.action !== 'D' && intervention.intervention.intervention === value)
  },

  intervention(state) {

    return state.intervention
  },

  interventions(state) {

    return state.episode.interventions.filter((intervention) => intervention.active === 1)
  },
  /**
   * INTERVENTION GETTERS =====================================================
   */

  /**
   *
   * @param {*} state
   * @returns {[]} of interventions from state
   */
  getInterventions(state) {
    return state.episode.interventions.filter(
      (intervention) => intervention.active === 1
    );
  },

  /**
   *
   * @param {*} state
   * @returns {{}} of the current intervention
   */

  getCurrentIntervention(state) {
    return state.intervention;
  },

  /**
   *
   * @param {*} state
   * @returns {} returns a matched object from episode interventions
   */

  getIntervention(state) {
    return state.episode.interventions.find(
      (intervention) =>
        intervention.intervention === state.intervention.intervention
    );
  },

  /**
   * @param {*} state 
   * @returns {boolean}
   */
   hasUnnotifiedInterventions(state) {

    return state.episode?.interventions?.some(intervention => intervention.notified === 0 && intervention.active === 1) ?? false
    
  },

  unnotifiedInterventions(state) {
    return state.episode?.interventions.filter(intervention => intervention.active === 1 && intervention.notified === 0).map(intervention => intervention.id);
  },

  /**
   * @param {*} state 
   * @returns {boolean}
   */
   hasUnnotifiedSymptoms(state) {

    return state.episode?.symptoms?.some(symptom => symptom.notified === 0 && symptom.active === 1) ?? false
    
  },

  unnotifiedSymptoms(state) {
    return state.episode?.symptoms.filter(symptom => symptom.active === 1 && symptom.notified === 0).map(symptom => symptom.uuid);
  },

  /**
   * @param {*} state 
   * @returns {boolean}
   */
   hasUnnotifiedVitalSigns(state) {

    return state.episode?.vital_signs?.some(vital_sign => vital_sign.notified === 0 && vital_sign.active === 1) ?? false
    
  },

  unnotifiedVitalSigns(state) {
    return state.episode?.vital_signs.filter(vital_sign => vital_sign.active === 1 && vital_sign.notified === 0).map(vital_sign => vital_sign.id);
  },
};
