const state = () => ({
    name: null,
    email: null,
    id: null,
    roles: [],
    facility: null,

    role: null,
    currentFacility: {
        id: null,
        name: null,
    },
})

const getters = {

    user(state) {

        return state
    },

    name(state) {

        return state.name
    },

    id(state) {

        return state.id
    },

    email(state) {

        return state.email
    },

    roles(state) {

        return state.roles
    },

    getRole(state) {
        return state.role
    },
    getRoles(state) {

        return state.roles
    },
    getCurrentFacility(state) {
        return state.currentFacility
    },
    isAdministrator(state) {

        return state.role === 'SUPERADMIN' ||
            state.role === 'ADMIN' ||
            state.role === 'ADMINRN'
    },
    isSiteAdministrator(state) {

        return state.role === "SUPERADMIN"
    },
}

const mutations = {
    setRole(state, role) {

        state.role = role
    },
    setRoles(state, roles) {

        state.roles = roles
    },
    setName(state, name) {

        state.name = name
    },
    setId(state, id) {

        state.id = id
    },
    setEmail(state, email) {

        state.email = email
    },
    setFacility(state, facility) {

        state.facility = facility
    },

    setCurrentFacility(state, facility) {

        state.currentFacility = facility
    }
}

const actions = {

    unload({commit}) {
        commit('setName', null)
        commit('setEmail', null)
        commit('setRoles', [])
        commit('setRole', null)
        commit('setId', null)
        commit('setCurrentFacility', {
            beds: 0,
            created_at: null,
            id: 0,
            name: '',
            status: 0
        })
    },

    loadUser({state, commit, dispatch}, user) {
        commit('setName', user.name)
        commit('setEmail', user.email)
        commit('setRoles', user.roles)
        commit('setFacility', user.facility)
        commit('setId', user.id)
        dispatch('setFacility')
        dispatch('setRoleByFacility', state.currentFacility.id)
    },

    setFacility({commit, state}) {

        if (state.facility) {

            let facility = state.roles.find((role) => {
                if (role.facility_id === state.facility.id) {

                    return role
                }
            })

            if (facility) {
                commit('setRole', facility.role)
                commit('setCurrentFacility', facility.facility)
                return
            }
        }
        if (state.roles.length > 0) {

            commit('setRole', state.roles[0].role.type)
            commit('setCurrentFacility', state.roles[0].facility)
        }
    },
    updateFacility({commit, state}, facilityId) {

        const newFacility = state.roles.find(facility => facility.facility_id === facilityId)

        if (newFacility) {
            commit('setCurrentFacility', newFacility.facility)
            commit('setRole', newFacility.role.type)
        }
    },
    setRoleByFacility({state, commit}, facilityId) {

        let role = 'UNDEFINED'
        let currentFacilityRole = state.roles.filter((role) => role.facility.id === facilityId)

        if (currentFacilityRole.length > 0) {

            role = currentFacilityRole[0].access
        }

        commit('setRole', role)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}