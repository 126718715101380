export default {
    resetIntervention(state) {
        state.intervention = {
            id: null,
            episode_id: null,
            intervention_id: null,
            selected_symptoms: [],
            selected_notifications: null,
            selected_tests: [],
            next_step: null,
            alert: null,
            status: null,
            page: null,
            active: null,
            notified: null,
            notified_at: null,
            read: null,
            read_at: null,
            created_at: null,
            updated_at: null,
            created_by: null,
            action: null,
        };
    },
    updateSelectedSymptoms(state, data) {
        state.intervention.selected_symptoms = data;
    },

    updateInterventionObject(state, data) {

        const intervention = data.intervention

        if (intervention.action !== 'C') {

            intervention.action = 'U'
        }

        state.episode.interventions.splice(data.index, 1, intervention)
    },

    SET_ESCALATION_NOTIFIED(state, data) {

        if(data.index >= 0) {
            state.episode.interventions[data.index].notified = data.state
        } else {

            console.error(`ESCALATION WITH INDEX ${data.index} NOT FOUND.`)
        }


    },

    setEpisodeInterventionId(state, episodeInterventionId) {
        state.episode.currentInterventionId = episodeInterventionId;
    },

    updateInterventionPageNumber(state, data) {
        state.intervention.page = data;
    },

    updateInterventionNextStep(state, data) {
        state.intervention.next_step = data;
    },

    updateSelectedNotifications(state, data) {
        state.intervention.selected_notifications = data;
    },

    updateSelectedMonitors(state, data) {
        state.intervention.selected_monitors = data;
    },

    setInterventionName(state, name) {
        state.intervention = name;
    },

    deleteIntervention(state, index) {
        state.episode.interventions[index].action = "D";
    },

    setAsCurrentIntervention(state, data) {
        state.intervention = data;
    },

    addIntervention(state, data) {
        data.episode_id = state.episode.id;
        state.episode.interventions.push(data);
    },

    updateIntervention(state, data) {
        state.episode.interventions[data.index] = data.value;
    },

    setIntervention(state, data) {
        if (!data) {
            state.episode.interventions = [];
            return;
        }
        state.episode.interventions[data.index] = data.value;
    },

    setCurrentIntervention(state, data) {
        state.intervention = data;
    },

    SET_ESCALATIONS(state, data) {

        state.episode.interventions = data
    }
};
