import actions from '@/store/modules/messages/actions.js'
import getters from '@/store/modules/messages/getters.js'
import mutations from '@/store/modules/messages/mutations.js'

const state = () => ({
  messages: [],
  isOpen: false,  //  todo: there's no mutation for this value, is it ever actually used?
  episodeId: null,
  panelState: false,
  sendAssessments: false,
  sendObservations: false,
  sendInterventions: false,
  changeInCondition: null,
  page: 1,
  last_page: 1,
});

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
