export default {

  clearIntervention(state) {
    state.intervention = null
  },
  clearAssessment(state) {
    state.assessment = null
  },
  clearSignsAndSymptom(state) {
    state.signsAndSymptom = null
  },

  setEpisodeErrorToFalse(state) {

    state.episodeHaserrors = false 
  },

  setEpisodeErrorToTrue(state) {

    state.episodeHaserrors = true
  },

  setEpisode(state, data) {
    console.warn("Setting Episode to: ", data)
    state.episode = data;
  },

  setOriginalEpisode(state, data) {
    state.originalEpisode = data;
  },

  setEpisodeFacilityId(state, id) {

    state.episode.facility_id = id
  },

  setEpisodeId(state, data) {
    state.episodeId = data;
  },

  updateMrnNumber(state, data) {
    state.episode.patient.mrn_number = data;
  },
  updateSocialSecurityNumber(state, data) {
    state.episode.patient.social_security_number = data;
  },

  updateFirstName(state, data) {
    state.episode.patient.name = data;
    // state.episode.name = data;
  },

  updateLastName(state, data) {
    state.episode.patient.last_name = data;
  },
  updateDateOfBirth(state, data) {
    state.episode.patient.date_of_birth = data;
  },
  updateBloodGroupId(state, data) {
    state.episode.patient.blood_group = data;
  },
  updateRoomNumber(state, data) {
    state.episode.bed_number = data;
  },
  updateGenderId(state, data) {
    state.episode.patient.gender_id = data;
  },
  updateGenderOther(state, data) {
    state.episode.patient.gender_other = data;
  },
  updateAccountNumber(state, data) {
    state.episode.account_number = data;
  },
  updateDateOfAdmission(state, data) {
    state.episode.admitted_at = data;
  },
  updateLanguageId(state, data) {
    state.episode.patient.language_id = data;
  },
  updateLanguageOther(state, data) {
    state.episode.patient.language_other = data;
  },
  updateEthnicityId(state, data) {
    state.episode.patient.ethnicity_id = data;
  },
  updateEthnicityOther(state, data) {
    state.episode.patient.ethnicity_other = data;
  },
  updateMedicalInsurancePlan(state, data) {
    state.episode.medical_insurance_type = data;
  },
  updateCareType(state, data) {
    state.episode.care_type_id = data;
  },
  updateDirectives(state, data) {
    let updatedDirectives = state.episode.directives
    updatedDirectives.push(data)
    state.episode.directives = [...new Set(updatedDirectives)];
  },
  updateErrors(state, data) {
    state.episodeErrors = data;
  },

  clearEpisodeError(state){
    state.episodeErrors = {}
  },

  updateDirectiveFullCode(state, data) {

    state.episode.directives.full_code = data
  },
  updateDirectiveDNR(state, data) {

    state.episode.directives.dnr = data
  },
  updateDirectiveDNI(state, data) {

    state.episode.directives.dni = data
  },
  updateDirectiveDNH(state, data) {

    state.episode.directives.dnh = data
  },
  updateDirectiveNoArtificialFeeding(state, data) {

    state.episode.directives.no_artificial_feeding = data
  },
  updateDirectiveComfortCare(state, data) {

    state.episode.directives.comfort_care = data
  },
  updateDirectiveHospiceCare(state, data) {

    state.episode.directives.hospice_care = data
  },
  updateDirectiveOther(state, data) {

    state.episode.directives.other = data
  },
  updateDirectiveOtherCare(state, data) {

    state.episode.directives.other_care = data
  },
  updateDirectiveDecisionMakingCapacity(state, data) {

    state.episode.directives.decision_making_capacity = data
  },
  updateDirectiveGoalOfCareDiscussed(state, data) {

    state.episode.directives.goal_of_care_discussed = data
  },
  updateDirectiveGoalOfCare(state, data) {

    state.episode.directives.goal_of_care = data
  },
};
