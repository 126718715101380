import axios from 'axios'
import _ from "lodash";

const state = () => ({
    episodes: [],
    facility: {
        name: 'Not Set',
        id: 0,
    },
    episodeDisplayState: "ALL_RESIDENTS",
    orderBy: 'action_priority',
    orderDirection: 'asc',
})

const getters = {
    getOrderBy(state) {

        return state.orderBy
    },

    getOrderDirection(state) {

        return state.orderDirection
    },

    episodes: (state, getters) => {
        switch (state?.episodeDisplayState) {
            case "INTERVENTIONS":
                return getters.getEpisodesWithInterventions;
            case "ALL_RESIDENTS":
            default:
                return getters.getEpisodes;
        }
    },
    getEpisodes: (state) => {
        return _.orderBy(state.episodes, [state.filter], [state.order])
    },
    getFacility: (state) => {
        return state.facility
    },
    getFacilityId: (state) => {
        return state.facility.id
    },
    getFacilityName: (state) => {
        return state.facility.name
    },
    getEpisodesWithInterventions: (state) => {
        // return state.episodes.filter(episode => episode.intervention_ids.length > 0)
        return state.episodes.filter(episode => episode.interventions.length > 0)
    },
    getEpisodeById: (state) => (id) => {
        return state.episodes.find(episode => episode.id === id)
    },
    alertCount: (state) => {
        let count = 0;
        state.episodes.forEach((episode) => {
            if (episode?.interventions) {
                episode.interventions?.forEach((intervention) => {
                    if (intervention.active === 1) {
                        count++
                    }
                })
            }
        });
        return count
    },
}

const actions = {

    unload({commit, dispatch}) {

        commit('setEpisodes', [])
        dispatch('setCurrentFacility', {name: 'Not Set', id: 0})
        commit('setOrderDirection', 'asc')
        commit('setOrderBy', 'action_priority')
        commit('SET_EPISODE_DISPLAY_STATE', 'ALL_RESIDENTS')
    },

    updateOrderBy({commit}, data) {

        commit('setOrderBy', data)
    },

    updateOrderDirection({commit}, data) {

        commit('setOrderDirection', data)
    },

    showAllResidents({commit}) {
        commit('SET_EPISODE_DISPLAY_STATE', "ALL_RESIDENTS")
    },
    showAllInterventions({commit}) {
        commit('SET_EPISODE_DISPLAY_STATE', "INTERVENTIONS")
    },
    async updateEpisodeFromEvent({state, dispatch}, data) {
        const index = state.episodes.findIndex(item => item.id === data.episode.id)

        if(index >= 0) {
            await dispatch("fetchEpisodeForDashboard", data.episode.id)
        }
    },
    async fetchEpisodeForDashboard({state, commit}, episodeId)  {

        const response = await axios.get(`episodes/${episodeId}/dashboard`)
            .then((response) => {
                const update = {
                    index: -1,
                    episode: null
                }

                const index = state.episodes.findIndex(item => item.id === response.data.data.id)
                if(index >= 0) {
                    update.index = index
                    update.episode = response.data.data
                    commit('updateEpisode', update)
                } else {
                    console.error("Did not find an episode to update.")
                }
            })
            .catch((error) => {
                console.error(`An error occurred during fetch of episode: ${error}`)
            })
        return !!response
    },
    updateFacilityEpisodes({state, commit}, episode) {
        //  todo: update the array of episodes in state to adjust the existing episode.
        console.warn("Episode: ", episode)
        const index = state.episodes.findIndex(item => item.id === episode.id)
        console.warn(`Searching state.episodes for id ${episode.id} found index number: ${index}`)

        if (index >= 0) {
            console.warn(`Updating episode with ID ${episode.id}`)

            const data = {
                index: index,
                episode: episode
            }

            console.log("Episode :", episode)
            console.log("Existing Episode: ", state.episodes[index])
            commit('updateEpisode', data)
            return
        }

        console.warn(`Adding episode with ID ${episode.id}`)
        commit('addEpisode', episode)
    },

    async getEpisodeByFacility({state, commit}) {

        let episodes;
        const response = await axios.get(`facility/${state.facility.id}/episodes`);
        episodes = response.data.episodes;

        if (episodes) {

            commit('resetEpisodes')
            commit('setEpisodes', episodes)
            return
        }

        commit('resetEpisodes')
    },
    setCurrentFacility({commit}, facility) {
        commit('setFacilityId', facility.id)
        commit('setFacilityName', facility.name)
    },
    setFacility({commit}, facilityId) {
        commit('setFacilityId', facilityId)
    },
    sortEpisodes({state, commit}, sortMethod, sortDirection = "asc") {
        console.warn("Filter Type: ", sortMethod)
        console.warn("Sort Direction: ", sortDirection)
        commit("SET_ORDER_TYPE", sortMethod)
        commit("SET_ORDER_DIRECTION", sortDirection)
        const sortedEpisodes = _.orderBy(state.episodes, [state.filter], [state.order])
        commit("setEpisodes", sortedEpisodes)
    },

    setEpisodePriorityToHigh({state, dispatch, commit}, episode) {

        dispatch("setEpisodePriority", state, episode, 1)
        commit("setEpisodeToPriorityToHigh", state, episode)
    },
    setEpisodePriority({state, commit}, episode, priority) {

        commit("setEpisodePriority", state, episode, priority)
    },
}

const mutations = {
    setOrderBy(state, orderBy) {
        state.orderBy = orderBy
    },

    setOrderDirection(state, orderDirection) {

        state.orderDirection = orderDirection
    },

    SET_ORDER_TYPE(state, value) {
        state.order_type = value
    },
    SET_ORDER_DIRECTION(state, value) {
        state.order_direction = value
    },
    SET_EPISODE_DISPLAY_STATE(state, setting) {
        state.episodeDisplayState = setting
    },
    resetEpisodes(state) {
        state.episodes = []
    },
    setEpisodes(state, episodes) {
        state.episodes = episodes
    },
    setEpisodePriority(state, episode, priority) {
        episode.priority = priority
    },
    setEpisodeToPriorityToHigh(state, episode) {
        episode.action_priority = 1
        episode.priority_high = true
    },
    setFacilityId(state, facilityId) {
        state.facility.id = facilityId
        //  state.facilityId = facilityId
    },
    setFacilityName(state, name) {
        state.facility.name = name
    },
    updateEpisode(state, data) {
        console.warn("In updateEpisode")
        console.table(data.episode)
        state.episodes[data.index] = data.episode
        state.episodes = [...state.episodes]
    },
    addEpisode(state, data) {
        state.episodes.push(data)
        state.episodes = [...state.episodes]
    },

    markEpisodeConversationAsRead(state, data) {

        const index = state.episodes.findIndex((episode) => {
            return episode.id === data;
        })

        if(index > -1) {
            state.episodes[index].hasUnreadMessages = false
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}