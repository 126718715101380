import axios from "axios";

export default {

    async configureEpisode({commit, dispatch}, data) {
        await dispatch("unloadEpisode")
        await dispatch("clearErrors")
        await commit("setEpisodeId", data.id)
        await commit("setEpisode", data)
        await commit("setOriginalEpisode", JSON.parse(JSON.stringify(data)))
    },

    async clearErrors({commit}) {
        commit("clearEpisodeError")
        commit("setEpisodeErrorToFalse")
    },

    async loadEpisodeById({commit, dispatch}, episodeId) {
        await dispatch("unloadEpisode")
        await commit("setEpisodeId", episodeId)
        await dispatch("fetchEpisode")
    },

    updateErrors({commit}, errors) {
        commit("updateErrors", errors);
    },

    handleErrors({commit}, errors) {
        commit("setEpisodeError")

        let errorObject = {
            title: errors.title,
            content: errors.content
        }

        commit("updateErrors", errorObject)
    },

    async fetchEpisode({state, dispatch}) {
        await dispatch("clearErrors")

        if (state.episodeId === null || state.episodeId === undefined) {
            console.error("The selected episode is null.");
            dispatch("unloadEpisode");
            return;
        }

        await axios.get(`episodes/${state.episodeId}`)
            .then((response) => {
                const data = response.data
                const status = response.status

                if (status === 200) {
                    // commit("setEpisode", data.data)
                    // commit("setOriginalEpisode", JSON.parse(JSON.stringify(data.data)))
                    dispatch("configureEpisode", data.data)
                }
            }).catch((error) => {
                if (error.status === 404) {
                    console.error(`An episode with an ID of ${state.episodeId} was not found.`)
                    dispatch("unloadEpisode")
                    return
                }

                console.error(`An error occurred ${error.status} response: `, error.response.body())
            })
    },

    loadEpisode({commit}, episode) {
        commit("setEpisode", episode)
    },

    unloadEpisode({commit, dispatch}) {
        commit("setEpisode", {});
        commit("setEpisodeId", null);
        dispatch("clearErrors")
        commit("clearIntervention")
        commit("clearAssessment")
        commit("clearSignsAndSymptom")
    },

    async createNewEpisode({state, dispatch}) {
        dispatch("clearErrors")

        const response = await axios.post(`episodes`, state.episode)
            .then(response => {
                return response.data
            })
            .catch(error => {

                dispatch("processError", error)
            })
        return !!response
    },

    async updateEpisode({state, dispatch}) {

        await dispatch("clearErrors")

        try {
            const response = await axios.put(
                `episodes/${state.episodeId}`,
                state.episode
            )
                .then(response => {

                    dispatch("configureEpisode", response.data.data)

                    return response
                })
                .catch(error => {

                    dispatch("processError", error)
                });

            return !!response
        } catch (error) {

            console.error("An unexpected error occurred updating the episode: ", error)
            return false
        }
    },

    processError({dispatch}, error) {
        console.error("This is being triggered: ", error)
        if (error.response.status === 422) {

            const errors = error.response.data;
            const errorMessage = errors.message ?? 'An Unknown Data Error Occurred.';
            const errorContent = Object.values(errors.errors).map(error => `${error}`);

            dispatch("handleErrors", {title: errorMessage, content: errorContent})
            return
        }

        if (error.response.status === 500) {

            const errorMessage = "Oops! An error occurred.";
            const errorContent = ["An unexpected error has occurred on our side. You should wait a moment and try again."];

            dispatch("handleErrors", {title: errorMessage, content: errorContent})
            return
        }

        if (error.response.status === 401 || error.response.status === 403) {

            const errorMessage = "You've Been Logged Out.";
            const errorContent = ["It looks like you are not allowed to perform that action, or you have been logged out. You will need to log in again."];

            dispatch("handleErrors", {title: errorMessage, content: errorContent})
            return
        }

        const errorMessage = "Oops! An error occurred.";
        const errorContent = ["An unexpected error has occurred. You should wait a moment and try again."];

        dispatch("handleErrors", {title: errorMessage, content: errorContent})
    },

    async saveEpisode({state, dispatch}) {
        if (state.episodeId === null || state.episodeId === 0) {
            return await dispatch("createNewEpisode")
        }

        if (state.episodeId !== null) {
            return await dispatch("updateEpisode")
        }

        return false
    },

    addNewResident({commit}) {
        const resident = {
            id: 0,
            account_number: '',
            room: '',
            name: '',
            patient: {
                id: 0,
                name: '',
                last_name: '',
                date_of_birth: '',
                social_security_number: '',
                blood_group: null,
                ethnicity_other: '',
                gender_other: '',
                language_other: '',
                mrn_number: '',
                gender_id: null,
                language_id: null,
                ethnicity_id: null,
                gender: '',
                ethnicity: '',
                language: '',
                family: [],
                has_social_security_number: false
            },
            immunizations: [],
            status: '',
            patient_symptoms: [],
            priority_high: false,
            intervention_needed: false,
            care_teams: [],
            intervention_ids: [],
            action_priority: 0,
            admission_date: '',
            transfer_date: null,
            facility_id: null,  //  todo figure this one out
            care_type_id: null,
            medical_insurance_id: null,
            messages: [],
            directive: null,
            vitals: [],
            episode_hospital_meds_before: [],
            episode_hospital_meds_after: [],
            high_risk_conditions: null,
            procedures: null,
            medications: null,
            interventions: [],
            is_new_episode: true,
        }
        commit("setEpisode", resident);
        commit("setOriginalEpisode", JSON.parse(JSON.stringify(resident)));
        commit("setEpisodeId", 0)
    },
};
