export default {

    addMedication({commit}, medication) {

        commit('updateMedications', medication)
    },

    removeMedication({dispatch}, medication) {

        if (medication.action === 'C') {

            dispatch("removeMedicationByFuzzySearch", medication)
            return
        }

        dispatch('removeMedicationById', medication)
    },

    removeMedicationById({commit, state}, medication) {

        const medicationIndex = state.episode.episode_medications.findIndex((med) => {
            return medication.id === med.id
        })

        if (medicationIndex >= 0) {

            commit('updateMedicationToDeleted', medicationIndex)
        }
    },

    removeMedicationByFuzzySearch({commit, state}, medication) {
        const medicationIndex = state.episode.episode_medications.findIndex((med) => {

            return medication.med_description === med.med_description
                && medication.dosage_frequency === med.dosage_frequency
                && medication.route === med.route
        })

        if (medicationIndex >= 0) {

            commit('removeMedicationFromArray', medicationIndex)
        }
    },

    updateMedication({commit, state}, medication) {

        const medicationIndex = state.episode.episode_medications.findIndex((med) => {

            return med.id === medication.id
        })

        if (medicationIndex > 0) {

            const data = {
                index: medicationIndex,
                medication: medication
            }

            commit('updateMedication', data)
            return
        }

        console.log("Medication Not Found")
    }
}