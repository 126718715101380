export default {
    removeFamilyItem(state, index) {

        const familyData = state.episode?.patient?.family[index]
        if (familyData) {
            familyData.action = 'D'
        }
    },

    refreshFamilyArray(state) {

        if (state.episode.patient.family) {

            state.episode.patient.family = [...state.episode.patient.family]
        }
    },

    addFamilyMember(state, family) {

        if(state.episode?.patient?.family) {

            state.episode.patient.family.push(family)
        }
    },

    updateFamilyMember(state, data) {

        if(state.episode.patient.family) {

            state.episode.patient.family.splice(data.index, 1)
            const family = data.family
            if(family.action !== 'C') {

                family.action = 'U'
            }
            state.episode.patient.family.push(family)

        }
    },
}