export default {

    setSignsAndSymptoms(state, data) {
        if (!data) {
            state.episode.signsAndSymptoms = [];
            return;
        }
        state.episode.signsAndSymptoms = data
    },

    updateSignsAndSymptom(state, data) {

        state.episode.signsAndSymptoms[data.index] = data.value
    },

    addSignsAndSymptom(state, data) {

        state.episode.signsAndSymptoms.push(data)
    },

    deleteSignsAndSymptom(state, index) {

        state.episode.signsAndSymptoms[index].action = 'D'
        console.log(state.episode.signsAndSymptoms[index].action)
    },

    setSignsAndSymptom(state, data) {

        state.signsAndSymptom = data
    },

    assessmentImmediacy(state, data) {

        state.assessment.immediacy = data
    },

    assessmentStartedAt(state, data) {

        state.assessment.started_at = data
    },

    assessmentSeverityDirection(state, data) {

        state.assessment.severity_direction = data
    },

    assessmentNegativeFactors(state, data) {

        state.assessment.negative_factors = data
    },

    assessmentPositiveFactors(state, data) {

        state.assessment.positive_factors = data
    },

    assessmentLastTreatment(state, data) {

        state.assessment.last_treatment = data
    },

    assessmentOtherFactors(state, data) {

        state.assessment.other_factors = data
    },

    assessmentIsReoccurrence(state, data) {

        state.assessment.is_reoccurrence = data
    },

    resetAssessment(state) {

        state.assessment = {
            id: null,
            episode_id: state.episode.id,
            immediacy: "false",
            is_immediate: false,
            started_at: null,
            severity_direction: "same",
            negative_factors: "",
            positive_factors: "",
            last_treatment: "",
            other_factors: "",
            is_reoccurrence: false,
            requires_notification: false,
            has_notified: false,
            status: 1,
            action: null,
            signs_and_symptom_id: null,
        }
    },

    setAssessment(state, data) {

        state.assessment = data
    },
}