import Vue from "vue"
import moment from "moment"

Vue.filter('formatTimeStamp', function (value) {
    try {

        return moment(value).format("YYYY-MM-DD HH:mm A")
    } catch (exception) {

        return value
    }
})