export default {
    updateCareDirective(state, directive, data) {
        switch (directive) {
          case "physical":
            state.episode.care_directives[0].physical = data;
            break;
          case "sensorial":
            state.episode.care_directives[0].sensorial = data;
            break;
          case "Devices":
            state.episode.care_directives[0].devices = data;
            break;
          case "Continences":
            state.episode.care_directives[0].continences = data;
            break;
          case "catheter":
            state.episode.care_directives[0].catheter = data;
            break;
          case "Bowels":
            state.episode.care_directives[0].bowels = data;
            break;
          case "FeedingTubes":
            state.episode.care_directives[0].tube_feeding = data;
            break;
          case "FeedingTubesProducts":
            state.episode.care_directives[0].tube_feeding.product = data;
            break;
          case "nutrition":
            state.episode.care_directives[0].nutrition = data;
            break;
          case "CardiacTreatments":
            state.episode.care_directives[0].cardiac_treatments = data;
            break;
          case "RespiratoryTreatments":
            state.episode.care_directives[0].respiratory = data;
            break;
          case "TherapyTreatments":
            state.episode.care_directives[0].therapies = data;
            break;
          case "SkinCare":
            state.episode.care_directives[0].skin_care = data;
            break;
          case "Risks":
            state.episode.care_directives[0].risks = data;
            break;
          case "InfectionControls":
            state.episode.care_directives[0].infection_control = data;
            break;
        }
      },
}