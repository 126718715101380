export default {

    setEscalationNotified({commit}, data) {

        commit("SET_ESCALATION_NOTIFIED", data);
    },

    reset({commit}) {
        commit("resetIntervention");
    },

    viewCurrentIntervention({commit}, intervention) {
        commit("setCurrentIntervention", intervention);
    },

    setEpisodeInterventionId({commit}, episodeInterventionId) {
        commit("setEpisodeInterventionId", episodeInterventionId);
    },

    updateEpisodeIntervention({commit, dispatch, state}) {

        const currentEscalation = state.intervention

        const index = currentEscalation?.action === "C"
            ? state.episode?.interventions?.findIndex(escalation => escalation.id === currentEscalation.episodeInterventionId)
            : state.episode?.interventions?.findIndex(escalation => escalation.id === currentEscalation.id)

        commit("updateInterventionObject", {intervention: currentEscalation, index: index})
        dispatch("setEscalationNotified", {index: index, state: false})
    },

    resolveEpisodeIntervention({commit, state}) {

        const currentEscalation = JSON.parse(JSON.stringify(state.intervention))
        const index = state.episode?.interventions?.findIndex(escalation => escalation.id === currentEscalation.id)
        currentEscalation.active = 0
        currentEscalation.action = 'D'
        commit("updateInterventionObject", {intervention: currentEscalation, index: index})
    },

    updateSelectedSymptomsAndIntervention({commit, dispatch}, value) {
        commit("updateSelectedSymptoms", value);
        dispatch("updateEpisodeIntervention");
    },

    updateSelectedNotificationsAndIntervention({commit, dispatch}, data) {
        commit("updateSelectedNotifications", data);
        dispatch("updateEpisodeIntervention");
    },

    updateSelectedMonitorsAndIntervention({commit, dispatch}, data) {
        commit("updateSelectedMonitors", data);
        dispatch("updateEpisodeIntervention");
    },

    setIntervention({commit, state}, data) {
        const index = state.episode.interventions.findIndex(
            (intervention) => intervention.intervention === data.intervention
        );

        if (index >= 0) {
            const update = {
                index: index,
                value: data,
            };

            commit("setIntervention", update);
        }
    },

    //  Intervention Actions

    setInterventionName({commit}, name) {
        commit("setInterventionName", name);
    },

    addOrUpdateIntervention({dispatch, state}, data) {
        //  does this intervention exist?
        const index = state.episode.interventions
            .map((e) => e.intervention)
            .indexOf(data.intervention);

        //  should we set this intervention as the current intervention
        let shouldSetAsCurrent = data.shouldSetAsCurrent
            ? data.shouldSetAsCurrent
            : false;

        if (index >= 0) {

            dispatch("updateEscalation", {index: index, shouldSetAsCurrent: shouldSetAsCurrent})
            return;
        }

        //  the intervention does not exist, create it
        dispatch("addEscalation", {data: data, shouldSetAsCurrent: shouldSetAsCurrent})
    },

    addEscalation({commit}, escalation) {

        //  is the intervention from an Assessment or from the Interventions screen?
        // if (escalation.data.invokedFrom !== "Interventions") {
        //     //  look up the intervention
        //     escalation.data.intervention = rootState.configuration.interventions.filter(
        //         (intervention) => intervention.intervention === escalation.data.intervention
        //     );
        // }

        //  set the active and notified values
        escalation.data.active = 1;
        escalation.data.notified = 0;
        escalation.data.action = "C";

        console.log("data after look up: ", escalation.data);

        if (escalation.shouldSetAsCurrent) {
            commit("setAsCurrentIntervention", escalation.data);
        }
        commit("addIntervention", escalation.data);
    },

    updateEscalation({state, dispatch, commit}, escalation) {

        //  the intervention exists, update the existing intervention
        const data = state.episode.interventions[escalation.index];

        //  check if this is a newly created intervention, or if this is an intervention that needs to be updated
        if (state.episode.interventions[escalation.index].action === "C") {
            //  the intervention is new
            data.action = "C";
        } else {
            data.action = "U";
        }

        //  set the active and notified values
        data.active = 1;
        data.notified = false;

        if (escalation.shouldSetAsCurrent) {
            commit("setAsCurrentIntervention", data);
        }

        commit("updateIntervention", {index: escalation.index, value: data});
        dispatch("setEscalationNotified", {index: escalation.index, state: false})
    },

    deleteIntervention({commit, state}, data) {
        const searchString = JSON.stringify(data);

        const index = state.episode.interventions.findIndex(function (
            intervention
        ) {
            let interventionSearch = JSON.stringify(intervention);

            if (interventionSearch === searchString) {
                return true;
            }
        });

        if (index >= 0) {
            commit("deleteIntervention", index);
        }
    },

    setEscalationForEpisode({commit}, data) {
        commit("SET_ESCALATIONS", data);
    },
};