export default {
  addCareTeamMember(state, data) {
    state.episode.care_teams.push(data);
  },

  removeCareTeamMember(state, index) {
    //  state.episode.care_teams.splice(index, 1);
    state.episode.care_teams[index].action = 'D'
  },

  updateCareTeamMember(state, data) {
    // state.episode.care_teams[data.index] = data.provider;
    state.episode.care_teams[data.index].action = data.provider.action
    state.episode.care_teams = [...state.episode.care_teams];
  },

  scrubCareTeamMember(state, index) {

    state.episode.care_teams.splice(index, 1)
  }
};
