export default {
    //  Vital Sign Actions
  deleteVitalSign({ commit, state }, data) {

    console.log(data)
    if (data.action === 'C') {
      
      const index = state.episode.vitals.findIndex((vital) => {
        
        return data.id === vital.id
      })

      if (index >= 0) {
        
        commit('removeVitalSignFromArray', index)
        return 
      }

      console.log('Vital Sign Not Found: ', data)
      return 
    }

    const index = state.episode.vitals.findIndex((vital) => {
      
      return data.id === vital.id
    })

    if (index >= 0) {
     
      commit('updateVitalToDeleted', index)
    } else {

      console.error("Vital Sign Not Found: ", data, index)
      console.table(state.episode.vitals)
    }
  },

  addVitalSign({ commit }, data) {
    
    //  if we want to do any unusual actions, like maybe validation and setting of Must Notify?
    commit('addVitalSign', data)
  },

  updateVitalSign({state, commit}, value) {
    
    const index = state.episode.vitals.map(e => e.id).indexOf(value.id);

    if (index >= 0) {
      
      commit("updateVitalSign", {index: index, value: value})
    }
  },
}