export default {

  episodeErrors(state){

    return state.episodeErrors
  },

  episodeHasErrors(state) {

    return state.episodeHasErrors
  },

  isDirty(state) {
    if (state.episodeId === null) return false;

    return (
      JSON.stringify(state.episode) !== JSON.stringify(state.originalEpisode)
    );
  },

  getEpisode(state) {
    return state.episode;
  },

  getOriginalEpisode(state) {
    return state.originalEpisode;
  },

  getEpisodeId(state) {
    return state.episodeId;
  },

  getEpisodeFacilityId(state) {

    return state.episode.facility_id ?? null
  },

  firstNameIsValid(state) {
    return (
      state.episode?.patient?.name !== "" &&
      state.episode?.patient?.name != null &&
      state.episode?.patient?.name.length >= 1
    );
  },
  lastNameIsValid(state) {
    return (
      state.episode.patient?.last_name !== "" &&
      state.episode.patient?.last_name != null &&
      state.episode.patient?.last_name.length >= 1
    );
  },
  dateOfBirthIsValid(state) {
    return (
      state.episode?.patient?.date_of_birth !== "" &&
      state.episode?.patient?.date_of_birth !== null
    );
  },
  bloodGroupIsValid(state) {
    return state.episode?.patient?.blood_group_id !== null;
  },
  genderIsValid(state) {
    return state.episode?.patient?.gender_id !== null;
  },
  accountNumberIsValid(state) {

    const ACCOUNT_NUMBER_MINIMUM_LENGTH = 4

    return state.episode?.account_number?.length >= ACCOUNT_NUMBER_MINIMUM_LENGTH;
  },
  admissionDateIsValid(state) {
    return (
      state.episode?.admitted_at !== "" &&
      state.episode?.admitted_at !== null
    );
  },
  languageIsValid(state) {
    return state.episode?.patient?.blood_group_id !== null;
  },
  ethnicityIsValid(state) {
    return state.episode?.patient?.language_id !== null;
  },
  ethnicityOtherIsValid(state) {
    return state.episode?.patient?.ethnicity_other !== null;
  },
  medicalInsuranceIsValid(state) {
    return state.episode?.medical_insurance_type !== null;
  },
  careTypeIsValid(state) {
    return state.episode?.care_type_id !== null;
  },

  mrnNumberIsValid(state) {

    const MRN_NUMBER_MINIMUM_LENGTH = 4
    const MRN_NUMBER_MAXIMUM_LENGTH = 8

    if(state.episode && state.episode.patient) {
      return (
          state.episode.patient.mrn_number !== "" &&
          state.episode.patient.mrn_number !== null &&
          state.episode.patient.mrn_number.length >= MRN_NUMBER_MINIMUM_LENGTH &&
              state.episode.patient.mrn_number.length <= MRN_NUMBER_MAXIMUM_LENGTH
      );
    }

    return false;

  },
  socialSecurityNumberIsValid({ state, rootState }) {
    if (!rootState) {
      return true;
    }

    if (rootState.user.isAdministrator || rootState.user.isSiteAdministrator) {

      //  We cannot perform actual validation because sometimes the social security number is unknown
      //  or is undefined (such as in the case of a newborn).
      //  We can only check that it is 9 numbers long.
      let socialSecurityNumber = state.patient.social_security_number.replace('-', '')

      return socialSecurityNumber.length === 9
    }
    //   let regexp = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
    //
    //   if (regexp.test(state.patient.social_security_number)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return true;
    // }
  },
  errors(state) {

    return state.episodeErrors
  }
};
