export default {
  addImmunization(state, immunization) {
    state.episode.immunizations.push(immunization);
  },

  setPatientImmunization(state, data) {
    state.episode.immunizations[data.index] = data.value;

    state.episode.immunizations = [...state.episode.immunizations];

    console.table(state.episode.immunizations);
  },
  removeImmunizationFromArray(state, index) {
    state.episode.immunizations.splice(index, 1);
  },

  updateImmunizationToDeleted(state, index) {
    state.episode.immunizations[index].action = "D";
  },

  updateImmunization(state, data) {

    state.episode.immunizations[data.index] = data.value
    state.episode.immunizations[data.index].action = 'U'
    state.episode.immunizations = [...state.episode.immunizations];
  }
};
