export default {
    //  Vital Sign Getters
  getVitalSigns(state) {
    
    return state.episode?.vitals?.filter(item => item.action !== 'D');
  },

  /**
   * @param {*} state 
   * @returns {boolean}
   */
   hasUnnotifiedVitalSigns(state) {

    return state.episode?.vitals?.some(vital => vital.notified === 0) ?? false
  },
}