import axios from "axios";

export default {
    namespaced: true,
    state: {
        genders: [],
        languages: [],
        ethnicities: [],
        medicalInsurancePlans: [],
        bloodGroups: [],
        careTypes: [],
        careProviders: [],
        immunizations: [],
        interventions: [],
        signsAndSymptoms: [],
    },

    getters: {
        genders(state) {
            return state.genders;
        },
        languages(state) {
            return state.languages;
        },
        ethnicities(state) {
            return state.ethnicities;
        },
        medicalInsurancePlans(state) {
            return state.medicalInsurancePlans;
        },
        bloodGroups(state) {
            return state.bloodGroups;
        },
        careTypes(state) {
            return state.careTypes;
        },
        immunizations(state) {
            return state.immunizations;
        },
        careProviders(state) {
            return state.careProviders;
        },

        getCareProviderOption(state) {
            return state.careProviders.map((e) => {
                return {
                    value: e.id,
                    // text: e.name + " (" + e.role.description + ")",
                    text: e.name + " (" + e.speciality + ")",
                };
            });
        },

        interventions(state) {
            return state.interventions;
        },

        signsAndSymptoms(state) {
            return state.signsAndSymptoms;
        },

        getInterventionList(state) {
            return state.interventions.map((intervention) => {
                return {
                    id: null,
                    episode_id: null,
                    intervention_id: intervention.id,
                    intervention: intervention.intervention,
                    selected_symptoms: [],
                    symptoms: intervention.symptoms,
                    selected_notifications: [],
                    notifications: intervention.notifications,
                    selected_monitors: [],
                    monitors: intervention.monitors,
                    selected_tests: [],
                    tests: intervention.tests,
                    next_step: 'Invoke',
                    notification: 'Inactive',
                    alert: null,
                    status: 'Inactive',
                    page: 1,
                    active: 0,
                    notified: 0,
                    notified_at: null,
                    read: 0,
                    read_at: null,
                    created_at: null,
                    updated_at: null,
                    created_by: null,
                    action: 'R'
                };
            });
        },

        getSignsAndSymptoms(state) {
            const sas = state.signsAndSymptoms.map((symptom) => {
                return {
                    value: symptom.id,
                    text: symptom.symptom,
                };
            });

            sas.push({
                value: null,
                disabled: true,
                text: "You must select a change in condition",
            });

            return sas;
        },

        getSelectedSignsAndSymptoms(state, value) {

            return state.signsAndSymptoms.find(symptom => symptom.id === value)
        },
    },

    mutations: {
        setGenders(state, value) {
            state.genders = value;
            let gender_undefined = {
                value: null,
                disabled: true,
                text: "Please Select a Gender",
            };
            state.genders.unshift(gender_undefined);
        },
        setLanguages(state, value) {
            state.languages = value;
            let language_underfined = {
                value: null,
                disabled: true,
                text: "Please Select a Language",
            };
            state.languages.unshift(language_underfined);
        },
        setEthnicities(state, value) {
            state.ethnicities = value;
            let ethnicities_underfined = {
                value: null,
                disabled: true,
                text: "Please Select an Ethnicity",
            };
            state.ethnicities.unshift(ethnicities_underfined);
        },
        setMedicalInsurancePlans(state, value) {
            state.medicalInsurancePlans = value;
            let medical_insurance_plans_undefined = {
                value: null,
                disabled: true,
                text: "Please Select a Medical Insurance Plan",
            };
            state.medicalInsurancePlans.unshift(medical_insurance_plans_undefined);
        },
        setBloodGroups(state, value) {
            state.bloodGroups = value;
            let bloodgroups_undefined = {
                value: null,
                disabled: true,
                text: "Please Select a Blood Group",
            };
            state.bloodGroups.unshift(bloodgroups_undefined);
        },
        setCareTypes(state, value) {
            state.careTypes = value;
            let caretype_undefined = {
                value: null,
                disabled: true,
                text: "Please Select a Care Type",
            };
            state.careTypes.unshift(caretype_undefined);
        },
        setImmunizations(state, value) {
            state.immunizations = value;
        },

        setCareProviders(state, value) {
            state.careProviders = value;
        },

        resetCareProviders(state) {
            state.careProviders = []
        },


        setInterventions(state, value) {
            state.interventions = value;
        },

        setSignsAndSymptoms(state, value) {
            state.signsAndSymptoms = value;
        },
    },

    //    Actions.
    actions: {

        async getCareProvidersByFacility({commit}, facilityId) {

            let careProviders
            const response = await axios.get(`config/${facilityId}/care-providers`)
            careProviders = response.data.data
            commit('resetCareProviders')

            if (careProviders) {
                commit('setCareProviders', careProviders)
            }
        },


        async loadConfigurationData({commit}) {
            const configData = await axios
                .get(`config`)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        console.log("Route not found.")
                        return {
                            success: false,
                            type: "not found"
                        }
                    }
                    console.table(error);
                    return error
                });

            if (configData.success) {
                configData.genders ? commit("setGenders", configData.genders) : [];
                configData.languages
                    ? commit("setLanguages", configData.languages)
                    : [];
                configData.ethnicities
                    ? commit("setEthnicities", configData.ethnicities)
                    : [];
                configData.medicalInsurancePlans
                    ? commit("setMedicalInsurancePlans", configData.medicalInsurancePlans)
                    : [];
                configData.bloodGroups
                    ? commit("setBloodGroups", configData.bloodGroups)
                    : [];
                configData.careTypes
                    ? commit("setCareTypes", configData.careTypes)
                    : [];
                configData.immunizations
                    ? commit("setImmunizations", configData.immunizations)
                    : ["did not load immunizations..."];
                configData.careProviders
                    ? commit("setCareProviders", configData.careProviders)
                    : [];
                configData.interventions
                    ? commit("setInterventions", configData.interventions)
                    : [];
                configData.signsAndSymptoms
                    ? commit("setSignsAndSymptoms", configData.signsAndSymptoms)
                    : [];
            } else {

                if (configData?.type === "not found") {
                    console.warn("File not found.")
                }

                if (configData?.type !== "not found") {

                    console.warn("An error occurred: ", configData)
                }
            }
        },
    },
};
