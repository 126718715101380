import axios from 'axios'
import moment from 'moment'

const EXPIRATION_TIME_MINUTES = 8;

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        sessionTimeout: null,
        isLoggedIn: false
    },

    getters: {

        authenticated( state ) {

            return state.isLoggedIn
        },

        user( state ) {

            return state.user
        },
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },

        SET_USER(state, user) {
            state.user = user
        },

        SET_EXPIRATION(state, expirationTime) {
            state.sessionTimeout = expirationTime;
        },

        SET_IS_LOGGED_IN(state, isLogged) {
            state.isLoggedIn = isLogged;
        },
    },
    actions: {
        async authenticate({dispatch}, credentials) {

            let response = await axios.post('login', credentials)
            return dispatch('handleLogin', response.data)
        },

        async handleLogin({dispatch}, response) {

            if(! response.access_token || ! response.me) {

                dispatch('clearTokens')
                return false
            }

            await dispatch('setUser', response)
            await dispatch('setLogin', response)
            return await dispatch('validateLogin')
        },

        async setUser({commit}, response) {

            const expirationTime = moment().add(EXPIRATION_TIME_MINUTES, 'm').format();
            await commit('SET_USER', response.me);
            await commit('SET_EXPIRATION', expirationTime);
        },

        async setLogin({commit}, response) {

            await commit('SET_TOKEN', response.access_token)
            await commit('SET_IS_LOGGED_IN', true);
        },

        validateLogin({state, dispatch}) {
            if(! state.token || ! state.user) {

                dispatch('clearTokens')
                return false
            }

            return true
        },


        logout({dispatch}) {

            axios.post('logout').then(
                (response) => {
                    if(response.status === 302 || response.status === 401) {
                        console.warn(`User logged out successfully.`)
                    }
                }
            ).catch(
                (error) => {
                    if(error.response.status === 401) {
                        console.warn(`User logged out via catch.`)
                    }
                }
            ).finally(
                dispatch('clearTokens')
            )
        },

        clearTokens({commit,  }) {
            commit('SET_TOKEN', null)
            commit('SET_USER', null)
            commit('SET_EXPIRATION', null)
            commit('SET_IS_LOGGED_IN', false);
            localStorage.clear();
        },
    },
}