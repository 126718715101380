export default {
  addVitalSign(state, data) {
    state.episode.vitals.push(data);
  },

  updateVitalSign(state, data) {
    state.episode.vitals[data.index] = data.value;
  },

  removeVitalSignFromArray(state, index) {
    state.episode.vitals.splice(index, 1);
  },

  updateVitalToDeleted(state, index) {
    state.episode.vitals[index].action = "D";
  },
};
