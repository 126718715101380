import interventionGetters from '@/store/modules/episode/interventions/getters.js'
import interventionActions from '@/store/modules/episode/interventions/actions.js'
import interventionMutations from '@/store/modules/episode/interventions/mutations.js'
import vitalGetters from '@/store/modules/episode/vitals/getters.js'
import vitalActions from '@/store/modules/episode/vitals/actions.js'
import vitalMutations from '@/store/modules/episode/vitals/mutations.js'
import residentGetters from '@/store/modules/episode/resident/getters.js'
import residentMutations from '@/store/modules/episode/resident/mutations.js'
import residentActions from '@/store/modules/episode/resident/actions.js'
import immunizationGetters from '@/store/modules/episode/immunization/getters.js'
import immunizationMutations from '@/store/modules/episode/immunization/mutations.js'
import immunizationActions from '@/store/modules/episode/immunization/actions.js'
import careteamActions from '@/store/modules/episode/careteam/actions.js'
import careteamGetters from '@/store/modules/episode/careteam/getters.js'
import careteamMutations from '@/store/modules/episode/careteam/mutations.js'
import familyGetters from '@/store/modules/episode/family/getters.js'
import familyActions from '@/store/modules/episode/family/actions.js'
import familyMutations from '@/store/modules/episode/family/mutations.js'
import medicationGetters from '@/store/modules/episode/medication/getters.js'
import medicationActions from '@/store/modules/episode/medication/actions.js'
import medicationMutations from '@/store/modules/episode/medication/mutations.js'
import caredirectiveGetters from '@/store/modules/episode/caredirective/getters.js'
import caredirectiveActions from '@/store/modules/episode/caredirective/actions.js'
import caredirectiveMutations from '@/store/modules/episode/caredirective/mutations.js'
import signsAndSymptomsActions from '@/store/modules/episode/signsAndSymptoms/actions'
import signsAndSymptomsMutations from '@/store/modules/episode/signsAndSymptoms/mutations'
import signsAndSymptomsGetters from '@/store/modules/episode/signsAndSymptoms/getters'
import validationActions from '@/store/modules/episode/validation/actions'
import validationGetters from '@/store/modules/episode/validation/getters'
import validationMutations from '@/store/modules/episode/validation/mutations'

const state = () => ({
  episode: null,
  episodeId: null,
  originalEpisode: null,
  intervention: {
    id: null,
    episode_id: null,
    intervention_id: null,
    selected_symptoms: [],
    selected_notifications: [],
    selected_tests: [],
    next_step: null,
    alert: null,
    status: null,
    page: null,
    active: null,
    notified: null,
    notified_at:null,
    read:null,
    read_at:null,
    created_at: null,
    updated_at: null,
    created_by: null,
    action: null
  },
  signsAndSymptom: null,
  symptom: null,
  episodeErrors: null,
  messagesLoading: false,
  episodeHasErrors: false,
  assessment: {
    id: null,
    episode_id: null,
    is_immediate: false,
    immediacy: "false",
    started_at: null,
    severity_direction: "same",
    negative_factors: "",
    positive_factors: "",
    last_treatment: "",
    other_factors: "",
    is_reoccurrence: false,
    requires_notification: false,
    has_notified: false,
    status: 1,
    action: null,
    signs_and_symptom_id: null,
  },
  isValid: false,
  validationErrors: [],
  validation: {
    episodeIsValid: false,
    directivesAreValid: false,
    assessmentsAreValid: false,
    patientIsValid: false,
    familyIsValid: false,
    observationsAreValid: false,
    careTeamIsValid: false,
    immunizationsAreValid: false,
    interventionsAreValid: false,
    medicationsAreValid: false,
  },

  currentInterventionId : null,
});

const getters = {

  ...signsAndSymptomsGetters,
  ...interventionGetters,
  ...vitalGetters,
  ...residentGetters,
  ...immunizationGetters,
  ...careteamGetters,
  ...familyGetters,
  ...medicationGetters,
  ...caredirectiveGetters,
  ...validationGetters,
};

//  actions
const actions = {

  ...signsAndSymptomsActions,
  ...interventionActions,
  ...vitalActions,
  ...residentActions,
  ...immunizationActions,
  ...careteamActions,
  ...familyActions,
  ...medicationActions,
  ...caredirectiveActions,
  ...validationActions,
};

//  mutations
const mutations = {

  ...signsAndSymptomsMutations,
  ...interventionMutations,
  ...vitalMutations,
  ...residentMutations,
  ...immunizationMutations,
  ...careteamMutations,
  ...familyMutations,
  ...medicationMutations,
  ...caredirectiveMutations,
  ...validationMutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
