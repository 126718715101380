export default {
    deleteFamilyItem({commit, state}, familyId) {

        const index = state.episode.patient.family.map(e => e.id).indexOf(familyId)

        if (index >= 0) {

            commit("removeFamilyItem", index)
            commit("refreshFamilyArray")
        }
    },

    updateFamilyMember({commit, state}, contact) {

        const index  = state.episode?.patient?.family?.findIndex(member => member.id === contact.id)
        if(index >= 0) {
            const data = {
                index: index,
                family: contact
            }
            commit("updateFamilyMember", data)
        }
        commit("refreshFamilyArray")
    },
    addFamilyMember(commit, family) {

        family.action = 'C'
        commit("addFamilyMember", family)
        commit("refreshFamilyArray")
    },
}