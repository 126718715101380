import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      meta: { transition: 'slide-right' },
      component: () => import( /* webpackChunkName: "about" */ '../views/DashboardView.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      meta: { transition: 'slide-left' },
      component: () => import( /* webpackChunkName: "login-panel" */ '../views/LoginPageView.vue')
    },
    {
      path: '/resetpassword/:token',
      name: 'resetpassword',
      meta: { transition: 'slide-right' },
      component: () => import( /* webpackChunkName: "reset-password-panel" */ '../views/ResetPasswordPageView.vue')
    },
    
    {
      path: '/test',
      name: 'test',
      meta: { transition: 'slide-right' },
      component: () => import(/* webpackChunkName: "test" */ '../components/PointClickCareIntegrationTest.vue')
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'resetpassword') {
    next();
    return;
  }

  const isAuthenticated = await store.getters['authentication/authenticated']

  if (to.name !== 'Login' && !isAuthenticated) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;