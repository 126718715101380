export default {

    markAsInvalid(state) {

        state.episode.isValid = false
    },

    markAsValid(state) {

        state.episode.isValid = true
    },

    clearValidationErrors(state) {

        state.episode.validationErrors = []
    },

    addValidationError(state, error) {

        state.episode.validationErrors.push(error)
    },
}